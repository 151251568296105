@media screen and (max-width: 345px) {
  .navcontainer {
    display: flex;
    flex-direction: column;
    background-color: #f2f2f2;
    gap: 2px;
    padding: 8px;
    justify-content: center;
  }
}

@media screen and (min-width: 346px) {
  .navcontainer {
    display: flex;
    flex-direction: row;
    background-color: #f2f2f2;
    gap: 20px;
    padding: 2px;
    justify-content: center;
    margin: auto;
  }
}

.nav {
  background-color: #f2f2f2;
  margin: auto;
  display: flex;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

body {
  font-family: Arial, sans-serif;

  margin: 0;

  padding: 0;
  overflow-x: hidden;
  max-width: 100vw;
  box-sizing: border-box;
 
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


/* Style for the close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

footer {
  background-color: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

footer p {
  margin: 0;
}

h1 {
  font-size: 26px;
  margin: 0;
}

header {
  background-color: #008080;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.keywords {
  display: none;
}

nav {
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}
.nav {
  padding: 20px;
  background-color: #f2f2f2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.nav.floating {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  /* Change this to your desired background color */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  text-align: center;
}
nav a {
  color: #333;
  text-decoration: none;
  padding: 5px 10px;
}

.a {
  color: #333;
  text-decoration: none;
  padding: 5px 10px;
  box-shadow: none;
  margin: auto;
}

nav a:hover {
  background-color: #333;
  color: #fff;
}

nav li {
  display: inline-block;
  margin: 0 10px;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Style for the greyed-out background */
.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black background */
  z-index: 1;
}

.p {
  max-width: 1000px;
  margin: auto;
  font-size: 18px;
}
/* Style for the green box */
.popup-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #209090; /* Green color */
  padding: 20px;
  border-radius: 10px;
  z-index: 2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

section {
  padding: 20px;

  text-align: center;
}

section h2 {
  font-size: 24px;

  margin: 0;
}

section p {
  font-size: 18px;
  line-height: 1.5;
  margin: 20px 0;
}

.servicearea {
  color: #fff;
}
.pb {
  display: 'flex';
  justify-content: 'center';
  text-align: 'top';
  padding-bottom: 5px ;
}
@media screen and (min-width: 550px) {
  .container {
    width: 100%;
    display: inline-flex;
    flex-direction: row-reverse;
    justify-content: center;
  }

  .floatcontainer {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .item1 {
    flex-grow: 1;
    max-width: 400px;
    text-align: center;
  }

  .item2 {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .item3 {
    flex-grow: 1;
    max-width: 500px;
    align-items: top;
  }
}

@media screen and (max-width: 549px) {
  .container {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .floatcontainer {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .item1 {
    flex-grow: 1;
    max-width: 400px;
  }

  .item2 {
    flex-grow: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .item3 {
    flex-grow: 1;
    max-width: 400px;
    align-items: top;
  }
}
